import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { IoLogoGithub, IoLogoLinkedin, IoLogoTwitter } from 'react-icons/io'

import { colours, fonts } from '../components/theme'
import FullScreen from '../components/fullscreen'
import Layout from '../layouts'

export const HomePageContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background: ${colours.primary};
  color: ${colours.accentWhite};
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
`

export const HomePageContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;

  @media (min-width: 960px) {
    width: 80%;
  }

  @media (min-width: 1200px) {
    width: 60%;
  }
`

export const HomePageHeading = styled.h1`
  font-family: ${fonts.nunitoSans};
`

export const HomePageText = styled.div`
  margin-bottom: 10px;
`
export const HomeSocialLinks = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const SocialLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener'
})`
  color: ${colours.accentWhite};
  margin: 0px 10px 0px 0px;
  padding: 0px;
`

const IndexPage = ({ location }) => {
  return (
    <Layout>
    <FullScreen>
      <HomePageContainer>
        <HomePageContent>
          <HomePageHeading>Hi. I’m Lakhveer Singh Jajj</HomePageHeading>
          <HomePageText>
            I’m a software engineer turned entrepreneur.
          </HomePageText>
          <HomePageText>
            Check out what I am currently working on ...{' '}
            <SocialLink href="https://www.moselle.io/">Moselle</SocialLink>
          </HomePageText>
          <HomePageText>
            <HomeSocialLinks>
              <SocialLink href="https://github.com/luckyjajj">
                <IoLogoGithub />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/in/luckyjajj">
                <IoLogoLinkedin />
              </SocialLink>
              <SocialLink href="https://twitter.com/luckyjajj">
                <IoLogoTwitter />
              </SocialLink>
            </HomeSocialLinks>
          </HomePageText>
        </HomePageContent>
      </HomePageContainer>
    </FullScreen>
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
