import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { colours } from '../components/theme'
import { GlobalStyle } from '../components/theme/global'

const Layout = ({ children, data }) => {
  return (
    <>
      <GlobalStyle />
      <Helmet
        title={'Hi! I’m Lakhveer'}
        meta={[
          {
            name: 'description',
            content:
              'I’m an software engineer turned entrepreneur.'
          },
          { name: 'theme-color', content: colours.primary },
          {
            name: 'google-site-verification',
            content: 'kQLianQikdbaHw5GYDor4zs8redBKkRvJJbqGnsfebM'
          }
        ]}
      />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.func,
  data: PropTypes.object
}

/* eslint no-undef: "off" */
export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default Layout
