import React from 'react'
import PropTypes from 'prop-types'

import { FullScreenContainer } from './styled'

export default function FullScreen({ children }) {
  return <FullScreenContainer>{children}</FullScreenContainer>
}

FullScreen.propTypes = {
  children: PropTypes.node,
}
